import React from "react";
import { useRouter } from "next/router";

import Typography from "@material-ui/core/Typography";

import PortfolioIcon from "@material-ui/icons/FolderOpen";
import NavSection from "@jumbo-coremat/CmtNavigation/Vertical/NavSection";
import IntlMessages from "@jumbo-theme/utils/IntlMessages";

import { userClientsPropType } from "util/customPropTypes";
import { usePortfoliosDataCache } from "hooks/useDataCache";
import { useCurrentUser } from "components/App/CurrentUserContext";
import { isAdmin } from "components/common/AdminOnly";
import useCurrentUserClient from "hooks/useCurrentUserClient";

const propTypes = {
  userClients: userClientsPropType
};

export default function PortfoliosMenuItem() {
  const { data: currentUserClient } = useCurrentUserClient() || {};
  const { currentUser } = useCurrentUser();
  const { client: { name: clientName } = {}, name: userClientName } =
    currentUserClient || {};
  let portfolios = usePortfoliosDataCache() || [];
  const router = useRouter();
  const { portfolioId } = router.query;
  let isError = false;

  if (!currentUserClient) return null;

  // I _hate_ to do this, but somewhere (that I haven't found yet)
  // Portfolios is set to a single portfolio in the data store.
  if (!Array.isArray(portfolios)) {
    console.trace("PortfoliosMenuItem: Portfolios is not an array");
    portfolios = [portfolios];
    isError = true;
  }

  const children = portfolios
    .filter((p) => p)
    .map((portfolio) => {
      // portfolioId comes from the URL, and will be a string
      const isCurrentRoute = String(portfolio.id) === portfolioId;
      const iconColor = isCurrentRoute ? "primary" : "inherit";

      return {
        name: portfolio.name,
        icon: <PortfolioIcon color={iconColor} />,
        type: "item",
        link: `/portfolios/${portfolio.id}?_loc=menu`
      };
    });

  children.unshift({
    name: (
      <Typography variant="subtitle2">
        <IntlMessages id="sidebar.section.portfolios" />
      </Typography>
    ),
    icon: <PortfolioIcon />,
    type: "item",
    link: "/portfolios?_loc=menu"
  });

  if (isError && isAdmin(currentUser)) {
    children.unshift({
      name: (
        <Typography variant="caption">Error (open developer tools)</Typography>
      ),
      icon: <PortfolioIcon color="red" />,
      type: "item",
      link: "#"
    });
  }

  const item = {
    name: (
      <Typography variant="inherit">
        {userClientName || clientName}{" "}
        <IntlMessages id="sidebar.dashboard.portfolios" />
      </Typography>
    ),
    children
  };

  return <NavSection {...item} />;
}

PortfoliosMenuItem.propTypes = propTypes;
