import React, { useContext } from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";

import { LAYOUT_TYPES } from "@jumbo-theme/constants/ThemeOptions";
import AppContext from "@jumbo-theme/components/contextProvider/AppContextProvider/AppContext";
import VerticalDefault from "@jumbo-theme/components/AppLayout/VerticalLayouts/VerticalDefault";
import VerticalMinimal from "@jumbo-theme/components/AppLayout/VerticalLayouts/VerticalMinimal";
import MinimalNoHeader from "@jumbo-theme/components/AppLayout/VerticalLayouts/MinimalNoHeader";
import ModernSideBar from "@jumbo-theme/components/AppLayout/VerticalLayouts/ModernSidebar";
import HorizontalDefault from "@jumbo-theme/components/AppLayout/HorizontalLayouts/HorizontalDefault";
import HorizontalDark from "@jumbo-theme/components/AppLayout/HorizontalLayouts/HorizontalDark";
import HorizontalMinimal from "@jumbo-theme/components/AppLayout/HorizontalLayouts/HorizontalMinimal";
import HorizontalTopMenu from "@jumbo-theme/components/AppLayout/HorizontalLayouts/HorizontalTopMenu";

import CurrentPageComponentContext from "util/CurrentPageComponentContext";

// const useStyles = makeStyles(() => ({
//   circularProgressRoot: {
//     position: "absolute",
//     left: 0,
//     top: 0,
//     zIndex: 1,
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center"
//   }
// }));

function FullScreenLayout({ children }) {
  return (
    <Box style={{ minHeight: "100vh", width: "100%", display: "flex" }}>
      {children}
    </Box>
  );
}
FullScreenLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const LayoutsMap = {
  [LAYOUT_TYPES.VERTICAL_DEFAULT]: VerticalDefault,
  [LAYOUT_TYPES.VERTICAL_MINIMAL]: VerticalMinimal,
  [LAYOUT_TYPES.VERTICAL_MINIMAL_NO_HEADER]: MinimalNoHeader,
  [LAYOUT_TYPES.VERTICAL_MODERN_SIDEBAR]: ModernSideBar,
  [LAYOUT_TYPES.HORIZONTAL_DEFAULT]: HorizontalDefault,
  [LAYOUT_TYPES.HORIZONTAL_DARK]: HorizontalDark,
  [LAYOUT_TYPES.HORIZONTAL_MINIMAL]: HorizontalMinimal,
  [LAYOUT_TYPES.HORIZONTAL_TOP_MENU]: HorizontalTopMenu
};

export default function AppLayout({ children }) {
  let Layout;
  const { layout } = useContext(AppContext);
  const Component = useContext(CurrentPageComponentContext);

  Layout = LayoutsMap[layout] || VerticalMinimal;

  if (Component?.isFullScreen) {
    Layout = FullScreenLayout;
  }

  return <Layout>{children}</Layout>;
}
AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
