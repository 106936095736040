import { addMinutes, format, isAfter, parse } from 'date-fns';
import { KeyboardEvent, KeyboardEventHandler, MouseEvent } from 'react';
import { Price, Statistic } from 'types/schema';
import { ReturnsObject } from 'util/PropTypes';

// Configuration
// TODO: i18n
const COUNT_FORMATS: { letter: string; limit: number }[] = [
  {
    // 0 - 999
    letter: '',
    limit: 1e3
  },
  {
    // 1,000 - 999,999
    letter: 'K',
    limit: 1e6
  },
  {
    // 1,000,000 - 999,999,999
    letter: 'M',
    limit: 1e9
  },
  {
    // 1,000,000,000 - 999,999,999,999
    letter: 'B',
    limit: 1e12
  },
  {
    // 1,000,000,000,000 - 999,999,999,999,999
    letter: 'T',
    limit: 1e15
  }
];

export function subtractDates(a: Date, b: Date) {
  // you can subtract dates in JS, but TS complains
  return (a as unknown as number) - (b as unknown as number);
}

export const handlePreventEnter: KeyboardEventHandler = (ev) => {
  if (ev.key !== 'Enter') {
    return;
  }

  ev.preventDefault();
};

export function asFormatted(n: string, fractionDigits = 0) {
  return parseFloat(n).toLocaleString(undefined, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  });
}

export function asMoney(
  n: string | number,
  { showFractional = true, fractionDigits = 2, currency = 'USD' } = {} // optional options
) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  const digits = showFractional ? fractionDigits : 0;

  // parseFloat allows for number or string, despite what TypeScript tells us
  return parseFloat(n as string).toLocaleString(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: digits,
    minimumFractionDigits: digits
  });
}

export function getCurrencySymbol(currency = 'USD') {
  return new Intl.NumberFormat(undefined, { style: 'currency', currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;
}

const [defaultCountFormat] = COUNT_FORMATS;
export function asMoneyReduced(
  n: string | number,
  fractionDigits = 1,
  currency = 'USD'
) {
  const x = parseFloat(n as string);
  const { limit, letter } =
    COUNT_FORMATS.find(({ limit: l }) => x < l) || defaultCountFormat;

  const value = (1000 * x) / limit;

  return (
    asMoney(value, { showFractional: true, fractionDigits, currency }) + letter
  );
}

export function asMoneyDiff(
  n: string | number,
  { showFractional = true, currency = 'USD' } = {}
) {
  const money = asMoney(n, { showFractional, currency });

  if (parseFloat(n as string) < 0) return money;

  return `+${money}`;
}

// no leading `+`
export function asPositivePct(n: string | number, fractionDigits = 1) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  return parseFloat(n as string).toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  });
}

export function asPct(n: string | number, fractionDigits = 1) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  const pct = asPositivePct(n, fractionDigits);

  if (parseFloat(n as string) < 0) return pct;

  return `+${pct}`;
}

export function asDiff(n: string | number, fractionDigits = 2) {
  if (n === undefined || n === null || Number.isNaN(n)) return '';

  return parseFloat(n as string).toLocaleString(undefined, {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  });
}

export function asFormattedDate(d?: Date | null) {
  if (!d) return '';

  return d.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
}

export function asUtcMonthYear(d?: Date | null) {
  if (!d?.getTimezoneOffset) return '';

  const offsetDate = addMinutes(d, d.getTimezoneOffset());

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore spend time figuring this one out. must have reference code from
  // an older version of date-fns
  return format(offsetDate, 'MMM ’yy', { timeZone: 'UTC' });
}

export function average(a: number[]) {
  return a.reduce((sum, i) => sum + i, 0) / a.length;
}

export function stDev(a: number[]) {
  const avg = average(a);
  const sumDiffSquares = a.reduce((sum, x) => sum + Math.pow(x - avg, 2), 0);

  return Math.pow(sumDiffSquares / (a.length - 1), 0.5);
}

type EventHandlerEvent<T> = KeyboardEvent<T> | MouseEvent<T>;
type A11yOnClickProps<T> = {
  onClick: (event: EventHandlerEvent<T>) => void;
  onKeyDown: (event: KeyboardEvent<T>) => void;
};
export function a11yOnClick<T>(
  fn: (event: EventHandlerEvent<T>) => void,
  t?: unknown // #this
): A11yOnClickProps<T> {
  return {
    onClick: fn,
    onKeyDown(event) {
      if (event.key === 'Enter') {
        return;
      }

      event.preventDefault();
      fn.call(t, event);
    }
  };
}

export function csvEscape(value: Parameters<typeof String>[0]) {
  if (value === null || value === undefined) return '';

  const str = String(value);

  if (!str.includes(',')) return value;

  return `"${str.replace(/"/g, '""')}"`;
}

export function isSameDay(d1?: Date | null, d2?: Date | null) {
  if (!d1 || !d2) return false;

  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth() &&
    d1.getUTCDay() === d2.getUTCDay()
  );
}

export const PriceTypes = {
  DAILY: 'daily',
  MONTHLY: 'end_of_month'
};

// TODO: I think this exists in date-fns
export function isSameMonth(d1?: Date | null, d2?: Date | null) {
  if (!d1 || !d2) return false;

  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth()
  );
}

export function isPricePeriodDateMatch(price: Price, date?: Date | null) {
  if (!price || !date) return false;

  const d = new Date(price.date);

  if (price.price_type === PriceTypes.MONTHLY) {
    return isSameMonth(d, date);
  }

  return isSameDay(d, date);
}

export const priceMatchThreshold = 1e-5;
export function isPriceMatch(
  px1: string | number,
  px2: string | number,
  threshold = priceMatchThreshold
) {
  if (px1 === px2) {
    return true;
  }

  const fPx1 = parseFloat(px1 as string);
  const fPx2 = parseFloat(px2 as string);

  return Math.abs(fPx1 - fPx2) <= threshold;
}

export function pad(n: string | number, zeroes = 1) {
  return `${'0'.repeat(zeroes)}${n}`.slice(-1 - zeroes);
}

export function asPriceDateFormat(d?: Date | null) {
  if (!d?.getUTCFullYear) return d;

  const yyyy = d.getUTCFullYear();
  const mm = pad(d.getUTCMonth());
  const dd = pad(d.getUTCDay());

  return `${yyyy}-${mm}-${dd}`;
}

export function generateKeyComparator<T>(sortBy: keyof T) {
  return ({ [sortBy]: a } = {} as T, { [sortBy]: b } = {} as T) => {
    if (a > b) return 1;
    if (a < b) return -1;

    return 0;
  };
}

// These date formats to not exactly match the docs, but:
// https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
const DATE_FNS_DATE_ONLY_FORMAT = 'yyyy-MM-dd';
export function isWithin(
  dateString: string | Date,
  numDays: number,
  { dateFormat = DATE_FNS_DATE_ONLY_FORMAT } = {}
) {
  let date: Date;
  if (typeof dateString === 'string') {
    date = parse(dateString, dateFormat, new Date());
  } else {
    date = dateString;
  }
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + numDays);

  return isAfter(date, futureDate);
}

// TODO: move to Statistics Helpers
export const StatisticTypes = {
  CALIBRATION: 'Calibration',
  INDEX: 'Index',
  SECURITY: 'Security'
};
export const StatisticNames = {
  CAGR: 'cagr',
  SHARPE_RATIO: 'sharpe_ratio',
  SORTINO_RATIO: 'sortino_ratio',
  ANNUALIZED_VOLATILITY: 'annualized_volatility',
  MAX_DRAWDOWN: 'max_drawdown',
  SKEWNESS: 'skewness',
  KURTOSIS: 'kurtosis',
  VALUE_AT_RISK: 'value_at_risk',
  ARITHMETIC_MEAN: 'arithmetic_mean',
  ARITHMETIC_MEAN_ANNUALIZED: 'arithmetic_mean_annualized',
  GEOMETRIC_MEAN: 'geometric_mean',
  GEOMETRIC_MEAN_ANNUALIZED: 'GEOMETRIC_MEAN_ANNUALIZED',
  RETURNS: 'returns'
};

export function getStatisticValue(
  statistics: Statistic[],
  type: Statistic['type'],
  name: Statistic['name']
) {
  if (!statistics) return;

  const statistic = statistics.find(
    (s) => s.statistic_type === type && s.name == name
  );

  return statistic?.value;
}

export function getBenchmarkKeyFromStatistic(statistic: Statistic) {
  let id: Statistic['id'] | null = null;
  if (!statistic) return null;

  const {
    statistic_type: type,
    index_id: indexId,
    security_id: securityId
  } = statistic;

  if (type === StatisticTypes.SECURITY) {
    id = securityId;
  } else if (type === StatisticTypes.INDEX) {
    id = indexId;
  }

  return getBenchmarkKey(type, id);
}

export function getBenchmarkKey(type: string, id: number | null = null) {
  if (id === null) return type;

  return `${type}-${id}`;
}

export function getStatisticsReturnsData(statistics: Statistic[]) {
  if (!statistics) return {};

  return statistics
    .filter((s) => s.name === StatisticNames.RETURNS)
    .reduce<ReturnsObject>((memo, statistic) => {
      const value = statistic.value as Record<string, string | number>;
      const key = getBenchmarkKeyFromStatistic(statistic);

      if (key) {
        Object.keys(value).forEach((dateStr: string) => {
          if (!memo[dateStr]) {
            memo[dateStr] = { date: new Date(dateStr) };
          }

          memo[dateStr][key] = Number(value[dateStr]);
        });
      }

      return memo;
    }, {});
}

interface StringOrObjectRecord {
  [key: string]: string | Record<string, string>;
}
export function parseUrlParams(query: string): StringOrObjectRecord {
  const params = new URLSearchParams(query);
  const result: StringOrObjectRecord = {};

  for (const [key, value] of params.entries()) {
    const match = key.match(/(\w+)\[(\w+)\]/);
    if (match) {
      const [, objKey, propKey] = match;
      if (!result[objKey]) {
        result[objKey] = {};
      }
      (result[objKey] as Record<string, string>)[propKey] = value;
    } else {
      result[key] = value;
    }
  }

  return result;
}
