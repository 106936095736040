import {
  lightTheme as jumboLightTheme,
  semiDarkTheme as jumboSemiDarkTheme,
  darkTheme as jumboDarkTheme
} from "@jumbo/theme/themeColors";

// here's your chance to tweak the theme

export const lightTheme = jumboLightTheme;

export const semiDarkTheme = jumboSemiDarkTheme;

export const darkTheme = {
  ...jumboDarkTheme,

  palette: {
    ...jumboDarkTheme.palette,

    background: {
      ...jumboDarkTheme.palette.background,

      paper: "#222222"
    }
  }
};
