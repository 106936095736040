/* eslint-disable import/no-named-as-default-member */
import PropTypes from "prop-types";

export { requiredIf } from "./customPropTypesLegacy";

export const idPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

export const datePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.instanceOf(Date)
]);

// given the difficulty of float precision, sometimes floats (decimals) will
// be returned as strings, which we will need to convert here
export const floatPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

export const allocationPropType = PropTypes.shape({
  id: idPropType,
  calibrationId: idPropType,
  securityId: idPropType,
  allocation: floatPropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  calibration: PropTypes.object,
  security: PropTypes.object
});

export const allocationsPropType = PropTypes.arrayOf(allocationPropType);

export const analysisPropType = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  calibrationJobs: PropTypes.arrayOf(PropTypes.object),
  calibrations: PropTypes.arrayOf(PropTypes.object),
  constraints: PropTypes.arrayOf(PropTypes.object),
  createdById: idPropType,
  portfolioId: idPropType,
  createdAt: datePropType,
  updatedAt: datePropType,

  createdBy: PropTypes.object,
  portfolio: PropTypes.object
});

export const analysesPropType = PropTypes.arrayOf(analysisPropType);

export const benchmarkPropType = PropTypes.shape({
  id: idPropType,
  index: PropTypes.object, // Index or Calibration
  date: datePropType,
  wavgReturn: PropTypes.number,
  wavgReturnsData: PropTypes.array, // be more specific if you'd like, but this is kind of an internal
  cashAllocation: PropTypes.number,
  warnings: PropTypes.arrayOf(PropTypes.string)
});

export const benchmarksPropType = PropTypes.arrayOf(benchmarkPropType);

export const calibrationJobPropType = PropTypes.shape({
  id: idPropType,
  status: PropTypes.string,
  createdAt: datePropType,
  updatedAt: datePropType
});

export const calibrationPropType = PropTypes.shape({
  id: idPropType,
  description: PropTypes.string,
  allocations: PropTypes.arrayOf(PropTypes.object),
  constraints: PropTypes.arrayOf(PropTypes.object),
  parentId: idPropType,
  portfolioAnalysisId: idPropType,
  calibrationJobId: idPropType,
  createdAt: datePropType,
  updatedAt: datePropType,

  parent: PropTypes.object,
  analysis: PropTypes.object,
  calibrationJob: PropTypes.object
});

export const childrenPropType = PropTypes.oneOfType([
  PropTypes.node,
  PropTypes.arrayOf(PropTypes.node)
]);

export const constraintPropType = PropTypes.shape({
  id: idPropType,
  type: PropTypes.string,
  constraint: floatPropType,
  minimumConstraint: floatPropType,
  portfolioId: idPropType,
  portfolioAnalysisId: idPropType,
  createdAt: datePropType,
  updatedAt: datePropType,

  // depending on the type, these may be populated
  priceCutoffAt: datePropType,
  returnsLookbackPeriods: PropTypes.number,
  covarianceLookbackPeriods: PropTypes.number,
  efficientFrontier: PropTypes.string,
  riskModel: PropTypes.string,
  targetRisk: PropTypes.number,
  alias: PropTypes.string,
  discrete: PropTypes.bool,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  sector: PropTypes.object,
  security: PropTypes.object,
  market: PropTypes.object,
  geo: PropTypes.object
});

export const constraintsPropType = PropTypes.arrayOf(constraintPropType);

export const clientPropType = PropTypes.shape({
  id: idPropType,
  is_system: PropTypes.bool,
  name: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  createdAt: datePropType,
  updatedAt: datePropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  portfolios: PropTypes.arrayOf(PropTypes.object)
});

export const clientsPropType = PropTypes.arrayOf(clientPropType);

export const periodReturnsPropType = PropTypes.objectOf(
  (propValue, propName, componentName) => {
    if (propName === "date") {
      if (propValue[propName] instanceof Date || propValue[propName] === 0) {
        return null;
      }

      return new Error(
        `Invalid prop '${propName}' supplied to '${componentName}', ` +
          `expected instance of Date. Validation failed. (${propValue[propName]})`
      );
    }

    if (Number.isFinite(propValue[propName])) return null;
    if (propValue[propName] === null) return null;

    return new Error(
      `Invalid prop '${propName}' supplied to '${componentName}', ` +
        `expected Number. Validation failed. (${propValue[propName]})`
    );
  }
);

export const returnsPropType = PropTypes.arrayOf(periodReturnsPropType);
export const returnsObjectPropType = PropTypes.objectOf(periodReturnsPropType);

export const indexPropType = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
  description: PropTypes.string,
  portfolioId: idPropType,
  createdById: idPropType,
  createdAt: datePropType,
  updatedAt: datePropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  allocations: PropTypes.arrayOf(PropTypes.object)
});

export const indicesPropType = PropTypes.arrayOf(indexPropType);

export const marketPropType = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
  description: PropTypes.string
});

export const marketsPropType = PropTypes.arrayOf(marketPropType);

export const portfolioPropType = PropTypes.shape({
  id: idPropType,
  description: PropTypes.string,
  clientId: idPropType,
  createdById: idPropType,
  createdAt: datePropType,
  updatedAt: datePropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  client: PropTypes.object,
  createdBy: PropTypes.object
});

export const portfoliosPropType = PropTypes.arrayOf(portfolioPropType);

export const priceCutoffSuggestionsPropType = PropTypes.shape({
  earliest_pricing_date_range: PropTypes.objectOf(PropTypes.number),
  earliest_requested_pricing_date_range: PropTypes.objectOf(PropTypes.number)
});

export const pricePropType = PropTypes.shape({
  id: idPropType,
  date: datePropType,
  price: floatPropType,
  priceType: PropTypes.string,
  securityId: idPropType,
  source: PropTypes.string, // TODO: make reference to TickerSource
  createdAt: datePropType,
  updatedAt: datePropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  security: PropTypes.object
});

export const pricesPropType = PropTypes.arrayOf(pricePropType);

const securityPropTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  sectorId: idPropType,
  geoId: idPropType,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  sector: PropTypes.object,
  geo: PropTypes.object
};

export const securityPropType = PropTypes.shape(securityPropTypes);

export const securitiesPropType = PropTypes.arrayOf(securityPropType);

export const securitySearchResultPropType = PropTypes.shape({
  ...securityPropTypes,
  meta: PropTypes.shape({
    source: PropTypes.string
  })
});

export const securitySearchResultsPropType = PropTypes.arrayOf(
  securitySearchResultPropType
);

export const sectorPropType = PropTypes.shape({
  id: idPropType,
  name: PropTypes.string,
  description: PropTypes.string
});

export const sectorsPropType = PropTypes.arrayOf(sectorPropType);

export const sectorAllocationPropType = PropTypes.shape({
  date: datePropType,
  sector: PropTypes.object,
  allocation: floatPropType
});

export const sectorAllocationsPropType = PropTypes.arrayOf(
  sectorAllocationPropType
);

export const userClientPropType = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  client: PropTypes.object,
  portfolios: PropTypes.arrayOf(PropTypes.object)
});

export const userClientsPropType = PropTypes.arrayOf(userClientPropType);

export const userPropType = PropTypes.shape({
  id: idPropType,
  email: PropTypes.string,

  // optional associations
  // no strict typing here, as it is assumed that a lower-level component will
  // define the propTypes it requires
  portfolios: PropTypes.arrayOf(PropTypes.object)
});
