import { shallowEqual, useSelector } from "react-redux";

import { Portfolio } from "resources/Portfolio";
import { UserClient, CurrentUserClient } from "resources/UserClient";

export function useDataCache() {
  return useSelector(({ DB }) => DB, shallowEqual);
}

export function useCurrentUserClientDataCache() {
  const { [CurrentUserClient.getCacheKey(null, false)]: resource } =
    useDataCache();

  return resource;
}

export function useUserClientsDataCache() {
  const { [UserClient.getCacheKey()]: resources } = useDataCache();

  return resources;
}

export function usePortfoliosDataCache() {
  const { [Portfolio.getCacheKey()]: resources } = useDataCache();

  return resources;
}
