import { initialState } from "./initialState";

export const ACTIONS = {
  WriteCollection: "@db/WRITE-COLLECTION",
  WriteMember: "@db/WRITE-MEMBER",
  Clear: "@db/CLEAR",
  Truncate: "@db/TRUNCATE",
  TruncateClientSpecific: "@db/TRUNCATE-CLIENT-SPECIFIC"
};

export default function DB(state = initialState.DB, action = null) {
  const { type, fnCacheKey, payload } = action;

  switch (type) {
    case ACTIONS.WriteMember: {
      const newState = {
        ...state,
        [fnCacheKey(false)]: payload
      };

      console.trace("DB.WriteMember", fnCacheKey(false), payload);

      return newState;
    }
    case ACTIONS.WriteCollection: {
      const newState = {
        ...state,
        [fnCacheKey(true)]: payload
      };

      console.log("DB.WriteCollection", fnCacheKey(true), payload);

      return newState;
    }
    case ACTIONS.Clear: {
      const newState = { ...state };
      // remove _both_ the Member and Collection cache entries
      delete newState[fnCacheKey(true)];
      delete newState[fnCacheKey(false)];
      return newState;
    }
    case ACTIONS.Truncate: {
      return {};
    }
    case ACTIONS.TruncateClientSpecific: {
      // TODO: as of now (July 2019), this is only used when switching
      // clients. consider making current client part of the cache key
      // in the meantime, keep the things we know are not client-specific

      const { Market, Sector, Security, "User-me": me, UserClient } = state;

      return { Market, Sector, Security, "User-me": me, UserClient };
    }
    default:
      return state;
  }
}

export function addMemberToCacheAction(dispatch, fnCacheKey) {
  return (payload) => {
    dispatch({ type: ACTIONS.WriteMember, fnCacheKey, payload });
    return payload;
  };
}

export function addCollectionToCacheAction(dispatch, fnCacheKey) {
  return (payload) => {
    dispatch({ type: ACTIONS.WriteCollection, fnCacheKey, payload });
    return payload;
  };
}

export function removeFromCacheAction(dispatch, fnCacheKey) {
  return (payload) => {
    dispatch({ type: ACTIONS.Clear, fnCacheKey, payload });
    return payload;
  };
}

export function truncateCacheAction(dispatch) {
  return (payload) => {
    dispatch({ type: ACTIONS.Truncate });
    return payload;
  };
}

export function truncateClientSpecificCacheAction(dispatch) {
  return (payload) => {
    dispatch({ type: ACTIONS.TruncateClientSpecific });
    return payload;
  };
}
