import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "next/router";
import NonIdealIcon from "@material-ui/icons/Error";
import Typography from "@material-ui/core/Typography";

import Error404 from "./Error404";

import NonIdealState from "components/NonIdealState/NonIdealState";
import AdminOnly from "components/common/AdminOnly";

const AUTH_ROUTES = ["/account/login", "/account/register"];

export class ErrorBoundaryComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]),
    router: PropTypes.object.isRequired // (from withRouter)
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  state = { error: null, hasError: false };

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentDidUpdate(prevProps) {
    const { hasError } = this.state;
    const { router: { asPath } = {} } = this.props;
    const { router: { asPath: prevAsPath } = {} } = prevProps;

    // i know, i know, no state changes in lifecycle methods
    // `!hasError` to cut down on the number of renders
    if (asPath === prevAsPath || !hasError) return;

    this.setState({ error: null, hasError: false });
  }

  componentDidCatch(error, errorInfo) {
    this.handleExpectedErrors(error);

    if (typeof window === "undefined") return;
    if (!window.newrelic) return;

    // You can also log the error to an error reporting service
    window.newrelic.noticeError(error, errorInfo);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  promiseRejectionHandler = (event) => {
    const { reason: error } = event;

    this.setState({ error, hasError: true });

    if (!this.handleExpectedErrors(error)) return;

    event.preventDefault();
  };

  handleExpectedErrors(error) {
    const { router } = this.props;
    const { asPath: destination, pathname: currentPath } = router;

    if (error?.response?.status === 401 && !AUTH_ROUTES.includes(currentPath)) {
      router.push({
        pathname: "/account/login",
        query: { destination, source: "client" }
      });

      return true;
    }

    // handled below
    if (error?.response?.status === 404) return true;

    return false;
  }

  renderErrorState() {
    const description = (
      <Fragment>
        <Typography>An unexpected error has occurred.</Typography>
        <Typography>The team has been notified</Typography>

        <AdminOnly>
          <pre>{JSON.stringify(this.state.error, null, "  ")}</pre>
        </AdminOnly>
      </Fragment>
    );

    return (
      <NonIdealState
        IconComponent={NonIdealIcon}
        title="Unexpected Error"
        description={description}
        iconProps={{ color: "error" }}
      />
    );
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      if (error?.response?.status === 404) {
        return <Error404 error={error} />;
      }

      return this.renderErrorState();
    }

    return children;
  }
}

export default withRouter(ErrorBoundaryComponent);
