import React from "react";

import AccountIcon from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";

import NavMenuItem from "@jumbo-coremat/CmtNavigation/Vertical/NavMenuItem";
import NavSection from "@jumbo-coremat/CmtNavigation/Vertical/NavSection";
import IntlMessages from "@jumbo-theme/utils/IntlMessages";

import { userClientsPropType } from "util/customPropTypes";
import {
  useCurrentUserClientDataCache,
  useUserClientsDataCache
} from "hooks/useDataCache";

const propTypes = {
  userClients: userClientsPropType
};

export default function ClientsMenuItem() {
  const currentUserClient = useCurrentUserClientDataCache();
  const userClients = useUserClientsDataCache() || [currentUserClient];

  if (!currentUserClient) return null;

  const children = userClients
    .filter((c) => c)
    .map((userClient) => {
      const name = userClient.name || userClient.client?.name;
      const isCurrentRoute = userClient.id === currentUserClient?.id;
      const iconColor = isCurrentRoute ? "primary" : "inherit";

      return {
        name: (
          <Typography
            noWrap
            style={{ textOverflow: "ellipsis" }}
            variant="inherit"
          >
            {name}
          </Typography>
        ),
        icon: <AccountIcon color={iconColor} />,
        type: "item",
        link: `/clients/${userClient.client_id}?_loc=menu`
      };
    });

  children.unshift({
    name: (
      <Typography variant="subtitle2">
        <IntlMessages id="sidebar.section.clients" />
      </Typography>
    ),
    icon: <AccountIcon />,
    type: "item",
    link: "/clients?_loc=menu"
  });

  const item = {
    name: <IntlMessages id="sidebar.dashboard.clients" />,
    children
  };

  const NavComponent = userClients?.length > 0 ? NavSection : NavMenuItem;

  return <NavComponent {...item} />;
}

ClientsMenuItem.propTypes = propTypes;
