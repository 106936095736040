// Browser console due to propTypes :shrug:
// Warning: forwardRef render functions do not support propTypes or
// defaultProps. Did you accidentally pass a React component?
/* eslint-disable react/prop-types */

import React, { forwardRef } from "react";

import clsx from "clsx";
import { useRouter } from "next/router";
import NextLink from "next/link";
import MuiLink from "@material-ui/core/Link";

import { noop } from "util";

function RefNextLinkComposed(props, ref) {
  const {
    to,
    linkAs,
    href: hrefIgnored, // NOSONAR
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
    ...other
  } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a ref={ref} {...other} />
    </NextLink>
  );
}
// RefNextLinkComposed.propTypes = {
//   as: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(URL)]),
//   href: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(URL)]),
//   linkAs: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(URL)]),
//   locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
//   passHref: PropTypes.bool,
//   prefetch: PropTypes.bool,
//   replace: PropTypes.bool,
//   scroll: PropTypes.bool,
//   shallow: PropTypes.bool,
//   to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(URL)])
// };

export const NextLinkComposed = forwardRef(RefNextLinkComposed);

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
function RefLink(props, ref) {
  const {
    activeClassName = "active",
    as: linkAs,
    className: classNameProps,
    href,
    noLinkStyle,
    role: roleIgnored, // NOSONAR
    ...other
  } = props;

  const { underline: muiLinkUnderline = "none" } = other;

  const router = useRouter();
  const pathname = typeof href === "string" ? href : href?.pathname;
  const className = clsx(classNameProps, {
    [activeClassName]: router.pathname === pathname && activeClassName
  });

  const isExternal =
    typeof href === "string" &&
    (href.indexOf("http") === 0 || href.indexOf("mailto:") === 0);

  if (isExternal) {
    if (noLinkStyle) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a className={className} href={href} ref={ref} {...other} />
      );
    }

    return (
      <MuiLink
        className={className}
        href={href}
        ref={ref}
        {...other}
        underline={muiLinkUnderline}
      />
    );
  }

  if (noLinkStyle) {
    return (
      <NextLinkComposed className={className} ref={ref} to={href} {...other} />
    );
  }

  return (
    <MuiLink
      component={NextLinkComposed}
      linkAs={linkAs}
      className={className}
      ref={ref}
      to={href}
      {...other}
      underline={muiLinkUnderline}
    />
  );
}
// RefLink.propTypes = {
//   activeClassName: PropTypes.string,
//   as: RefNextLinkComposed.propTypes.as,
//   className: PropTypes.string,
//   href: RefNextLinkComposed.propTypes.href,
//   linkAs: RefNextLinkComposed.propTypes.as,
//   noLinkStyle: PropTypes.bool,
//   role: PropTypes.any // discarded
// };

const ForwardedLink = forwardRef(RefLink);

function useLocationToUseRouter() {
  const router = useRouter();

  const { asPath = "", pathname } = router;

  return {
    search: asPath.match(/\?[^#]+/)?.[0],
    pathname
  };
}

function useHistoryToUseRouter() {
  const router = useRouter();

  console.log("useHistoryToUseRouter", {
    "router keys": Object.keys(router),
    router
  });

  return {
    listen: () => noop,
    location: router
  };
}

export const NavLink = ForwardedLink;
export const useLocation = useLocationToUseRouter;
export const useHistory = useHistoryToUseRouter;
