import React from "react";
import SecuritiesIcon from "@material-ui/icons/TrendingUp";
import NavSection from "@jumbo-coremat/CmtNavigation/Vertical/NavSection";
import IntlMessages from "@jumbo-theme/utils/IntlMessages";

import AdminOnly from "components/common/AdminOnly";

export default function AdminSecuritiesMenuItem() {
  const item = {
    name: <IntlMessages id="sidebar.section.securities" />,
    icon: <SecuritiesIcon />,
    type: "item",
    link: "/securities"
  };
  return (
    <AdminOnly>
      <NavSection
        name={<IntlMessages id="sidebar.dashboard.securities" />}
        // eslint-disable-next-line react/no-children-prop
        children={[item]}
      />
    </AdminOnly>
  );
}
