import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { createWrapper } from "next-redux-wrapper";

import reducers from "../reducers";

const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const initStore = () => createStore(reducers, bindMiddleware([thunk]));
export const wrapper = createWrapper(initStore);
