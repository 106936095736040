// cSpell:ignore abortcontroller

// move abortcontroller-polyfill to a more global location if AbortController
// is used elsewhere in code
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

export type Id = number | string;
export type Params = Record<
  string,
  string | string[] | number | number[] | boolean
>;
export type FormData<T> = Partial<T>;
export type Options = Record<string, unknown> &
  Partial<{
    host: string;
    headers: Record<string, string>;
    middleware: Record<string, Middleware>;
  }>;
export type Resource = Record<string, unknown> & { id?: Id };

export enum Method {
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Destroy = 'DELETE'
}

export enum ModelState {
  complete = 'complete',
  partial = 'partial'
}

export enum ModelSource {
  server = 'server',
  cache = 'cache'
}

export class ModelError extends Error {}
export class ValidationError extends ModelError {}
export class ResponseError extends ModelError {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);

    this.response = response;
  }
}

export class ResourceMeta {
  lastUpdated: Date;
  state: string;
  source: string;

  constructor(
    lastUpdated = new Date(),
    state = ModelState.complete,
    source = ModelSource.server
  ) {
    this.lastUpdated = lastUpdated;
    this.state = state;
    this.source = source;
  }
}

type AsQuery<T extends string> = { [K in T]: Id };
export type UrlPath = `/${string}`;
export type RequiredParamsFromPath<Path extends string> =
  Path extends `${infer Prefix}/:${infer Param}/${infer Suffix}`
    ? RequiredParamsFromPath<Prefix> & // recurse on prefix
        AsQuery<Param> & // convert param into RouteParams
        RequiredParamsFromPath<Suffix> // recurse on suffix
    : Path extends `${infer Prefix}/:${infer Param}`
    ? RequiredParamsFromPath<Prefix> & // recurse on prefix
        AsQuery<Param>
    : // eslint-disable-next-line @typescript-eslint/ban-types
      {};

type MemberRoute = string; // NOSONAR
type CollectionRoute = string; // NOSONAR
type Middleware = (
  Class: typeof DataModel,
  method: Method | MemberRoute | CollectionRoute,
  r: Response
) => Promise<Response>;

export default class DataModel<
  R extends Resource,
  ResourcePath extends UrlPath,
  T extends typeof DataModel<R, ResourcePath> = typeof DataModel
> {
  // use "/api" while we proxy, otherwise:
  //   process.env.API_SERVER_HOST;
  // TODO: there is a better place for this. Model should not know that /api
  // is the NextJS path for API data
  static host = '/api';
  // replace with static getters to appease sonar?
  static resourcePath = '/';
  static resourceParam = 'datum';
  static cacheKey = 'DataModel';
  static Method = Method;

  static MemberRoutes = {};
  static CollectionRoutes = {};

  static hasMemberRoute(route: string) {
    return Object.values(this.MemberRoutes).includes(route);
  }
  static hasCollectionRoute(route: string) {
    return Object.values(this.CollectionRoutes).includes(route);
  }

  static middleware: Record<string, Middleware> = {};

  host: string;
  headers: Record<string, string>;
  middleware: Record<string, Middleware>;
  resourcePath?: string;
  abortController: AbortController;

  constructor(
    attributes?: RequiredParamsFromPath<ResourcePath> | null,
    options: Options = {}
  ) {
    const {
      host = (this.constructor as T).host,
      headers = {},
      middleware
    } = options;

    this.host = host;
    this.headers = headers;
    this.middleware = middleware ?? {};

    this.updateAttributes(attributes as RequiredParamsFromPath<ResourcePath>);

    this.abortController = new AbortController();
  }

  memberUrl(memberRoute: string, id: Id, params: Params | null = null) {
    return this._urlWithParams(`${id}/${memberRoute}`, params);
  }

  collectionUrl(collectionRoute: string, params: Params | null = null) {
    return this._urlWithParams(collectionRoute, params);
  }

  updateAttributes(attributes?: RequiredParamsFromPath<ResourcePath> | null) {
    this.resourcePath = this._replaceTokens(
      (this.constructor as T).resourcePath,
      attributes
    );
  }

  abort() {
    this.abortController.abort();
  }

  _getMiddleware() {
    return Object.values({
      ...DataModel.middleware,
      ...this.middleware
    });
  }

  list(params?: Params): Promise<R[]> {
    const url = this._urlWithParams(null, params);

    let promise = fetch(url, {
      credentials: 'same-origin',
      headers: this._getRequestHeaders(),
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, 'list', r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  create(formData: FormData<R>) {
    const url = this._urlWithParams();

    let promise = fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        [(this.constructor as T).resourceParam]: formData
      }),
      headers: this._getRequestHeaders(),
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, 'create', r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  read(id: Id, params?: Params): Promise<R> {
    if (!id) {
      return Promise.reject(
        new ValidationError(
          `id is required to read ${(this.constructor as T).name}`
        )
      );
    }

    const url = this._urlWithParams(id, params);

    const options: RequestInit = {
      credentials: 'same-origin',
      headers: this._getRequestHeaders(),
      signal: this.abortController.signal
    };
    let promise = fetch(url, options);

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, 'read', r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  update(id: Id, formData: Partial<FormData<R>>) {
    if (!id) {
      return Promise.reject(
        new ValidationError(
          `id is required to update ${(this.constructor as T).name}`
        )
      );
    }

    const url = this._urlWithParams(id);

    let promise = fetch(url, {
      credentials: 'same-origin',
      method: 'PATCH',
      body: JSON.stringify({
        [(this.constructor as T).resourceParam]: formData
      }),
      headers: this._getRequestHeaders(),
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, 'update', r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  destroy(id: Id) {
    if (!id) {
      return Promise.reject(
        new ValidationError(
          `id is required to destroy ${(this.constructor as T).name}`
        )
      );
    }

    const url = this._urlWithParams(id);

    let promise = fetch(url, {
      credentials: 'same-origin',
      method: 'DELETE',
      headers: this._getRequestHeaders(),
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, 'destroy', r)),
      promise
    );

    return (
      promise
        .then(this._checkStatus)
        .then(this._parseJson)
        // .then(this._clearCache)
        .catch(this._handleAbort)
    );
  }

  member(method: Method, memberRoute: string, id: Id, params?: Params) {
    let url;
    const fetchOptions: RequestInit = {};
    if (!method) {
      return Promise.reject(new ValidationError('method is required'));
    }
    if (!memberRoute) {
      return Promise.reject(new ValidationError('route is required'));
    }
    if (!id) {
      return Promise.reject(
        new ValidationError(
          `id is required for ${
            (this.constructor as T).name
          } member route "${memberRoute}"`
        )
      );
    }

    if (method === Method.Get) {
      url = this.memberUrl(memberRoute, id, params);
    } else {
      url = this.memberUrl(memberRoute, id);
      fetchOptions.body = JSON.stringify({
        [(this.constructor as T).resourceParam]: params
      });
    }

    let promise = fetch(url, {
      credentials: 'same-origin',
      method,
      headers: this._getRequestHeaders(),
      ...fetchOptions,
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, memberRoute, r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  collection(
    method: Method,
    collectionRoute: string,
    params?: Params | FormData<R>
  ) {
    let url;
    const fetchOptions: RequestInit = {};

    if (!method) {
      return Promise.reject(new ValidationError('method is required'));
    }
    if (!collectionRoute) {
      return Promise.reject(new ValidationError('route is required'));
    }

    if (method === Method.Get) {
      url = this.collectionUrl(collectionRoute, params as Params);
    } else {
      url = this.collectionUrl(collectionRoute);
      fetchOptions.body = JSON.stringify(params);
    }

    let promise = fetch(url, {
      credentials: 'same-origin',
      method,
      headers: this._getRequestHeaders(),
      ...fetchOptions,
      signal: this.abortController.signal
    });

    promise = this._getMiddleware().reduce(
      (p, fnMiddleware) =>
        p.then((r) => fnMiddleware(this.constructor as T, collectionRoute, r)),
      promise
    );

    return promise
      .then(this._checkStatus)
      .then(this._parseJson)
      .catch(this._handleAbort);
  }

  // protected

  _urlWithParams(id?: Id | null, params?: Params | null) {
    let url = this.host + this.resourcePath;

    if (id) {
      url += `/${id}`;
    }

    if (!params || !Object.keys(params).length) {
      return url;
    }

    const query = Object.keys(params)
      .map((key) => {
        const val = params[key];
        if (Array.isArray(val)) {
          return val.map(
            (v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v)}`
          );
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
      })
      .flat()
      .join('&');

    return [url, query].join('?');
  }

  _getRequestHeaders(): Headers {
    return new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...this.headers
    });
  }

  _checkStatus(response: Response) {
    if (response.status >= 200 && response.status < 300) return response;

    // response code for create actions, where the server decides it should not
    // re-create (initial example of this is when a user creates a Calibration
    // Job for an Analysis. If it exists, no reason to re-create)
    if (response.status === 304) return response;

    return response
      .text()
      .catch(() => {
        // important that `catch` comes before `then`
        throw new ResponseError(response.statusText, response);
      })
      .then((errorTextJson) => {
        let errorText;
        try {
          const objectOrString = JSON.parse(errorTextJson);

          if (
            objectOrString instanceof Object &&
            !(objectOrString instanceof Array)
          ) {
            errorText = objectOrString.error;
          } else {
            errorText = objectOrString;
          }
        } catch {
          errorText = response.statusText;
        }
        throw new ResponseError(errorText, response);
      });
  }

  _parseJson(response: Response) {
    // instead of response.json() which fails for empty responses, use this:
    return response.text().then((text) => (text ? JSON.parse(text) : null));
  }

  _replaceTokens(url: string, attributes?: Record<string, unknown> | null) {
    let match;
    let output = url;

    if (!attributes || !Object.keys(attributes).length) {
      return url;
    }

    const reTokenizer = /:([a-z]\w+\b)/gi;
    while ((match = reTokenizer.exec(url))) {
      output = output.replace(
        match[0],
        (attributes[match[1]] ?? match[0]) as string
      );
    }

    return output;
  }

  _handleAbort(error: Error) {
    if (error.name === 'AbortError') {
      return; // noop
    }

    throw error;
  }

  static getCacheKey(props: Resource = {}, isCollection = true, suffix = null) {
    let key = this.getCacheKeyBaseName(props);

    if (!isCollection) {
      const { id } = props;
      key += `-${id}`;
    }

    if (suffix) key += `-${suffix}`;

    return key;
  }

  static getCacheKeyBaseName(_props = {}) {
    return this.cacheKey;
  }
}
