import React, { Fragment, useContext, useEffect, useState } from "react";

import classnames from "classnames";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SettingsIcon from "@material-ui/icons/Settings";

import CustomizerContent from "@jumbo-theme/components/AppLayout/partials/customizer/CustomizerContent";
import Theme from "@jumbo-theme/components/AppLayout/partials/customizer/Theme";
import OtherSettings from "@jumbo-theme/components/AppLayout/partials/customizer/OtherSettings";
import SidebarSize from "@jumbo-theme/components/AppLayout/partials/customizer/SidebarSize";
import ThemeColor from "@jumbo-theme/components/AppLayout/partials/customizer/ThemeColor";
import SidebarOption from "@jumbo-theme/components/AppLayout/partials/customizer/SidebarOption";
// Not all navigation layouts have been properly overridden
// import NavigationLayout from "@jumbo-theme/components/AppLayout/partials/customizer/NavigationLayout";
// import DisplayLayout from "@jumbo-theme/components/AppLayout/partials/customizer/DisplayLayout";

import AppContext from "@jumbo-theme/components/contextProvider/AppContextProvider/AppContext";
import CmtDrawer from "@jumbo-coremat/CmtDrawer";

const useStyles = makeStyles((theme) => ({
  customizerBtn: {
    color: theme.palette.common.white,
    width: "1.25em",
    height: "1.25em",
    minWidth: 10

    // [theme.breakpoints.up("lg")]: {
    //   width: 45,
    //   height: 45
    // },
    // [theme.breakpoints.up("xl")]: {
    //   width: 60,
    //   height: 60
    // }
  },
  iconSetting: {
    width: "1.25em",
    height: "1.25em",

    [theme.breakpoints.up("lg")]: {
      fontSize: 30
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: 36
    }
  },
  customizerOption: {
    width: 300,
    [theme.breakpoints.up("sm")]: {
      width: 380
    }
  }
}));

export default function UiSettings(props) {
  const { showTourGuide } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (showTourGuide) {
      setIsOpen(false);
    }
  }, [showTourGuide]);

  return (
    <Fragment>
      <Button
        className={classnames(classes.customizerBtn, "Cmt-customizerBtn")}
        onClick={() => setIsOpen(true)}
        data-tut="reactour__customizer"
      >
        <SettingsIcon className={classes.iconSetting} />
      </Button>
      <CmtDrawer
        variant="temporary"
        open={isOpen}
        anchor="right"
        onClose={() => setIsOpen(false)}
        classes={{
          paper: "drawer-sidebar"
        }}
      >
        <div className={classes.customizerOption}>
          <CustomizerContent onClose={() => setIsOpen(false)} {...props}>
            <Theme />
            <ThemeColor />
            {/* <NavigationLayout /> */}
            {/* <DisplayLayout /> */}
            <Hidden lgUp>
              <SidebarOption />
              <SidebarSize />
            </Hidden>
            <OtherSettings />
          </CustomizerContent>
        </div>
      </CmtDrawer>
    </Fragment>
  );
}
