import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";

import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";

import CmtAvatar from "@jumbo-coremat/CmtAvatar";
import { AuthMethods } from "@jumbo/services/auth";
import { CurrentAuthMethod } from "@jumbo-theme/constants/AppConstants";
import SidebarThemeContext from "@jumbo-coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";

import { userPropType, useCurrentUser } from "containers/withCurrentUser";
import { a11yOnClick } from "components/util/helpers";
import Link from "components/common/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 16px 12px 16px",
    borderBottom: (props) => `solid 1px ${props.sidebarTheme.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: (props) => props.sidebarTheme.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

function AnonymousSidebarHeader() {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });

  return (
    <Box className={classes.root}>
      <Link to="/account/login">Login</Link>
    </Box>
  );
}

function LoggedInSidebarHeader({ currentUser }) {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const dispatch = useDispatch();
  const router = useRouter();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuthMethods[CurrentAuthMethod].onLogout());
    router.push("/");
  };

  return (
    <Box className={classes.root}>
      <CmtAvatar src="https://via.placeholder.com/150" alt="User Avatar" />
      <Box
        className={classes.userInfo}
        {...a11yOnClick(handlePopoverOpen, this)}
      >
        <Box
          className="pointer"
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <div className="mr-2">
            <Typography
              className={classes.userTitle}
              component="h3"
              variant="h6"
            >
              Welcome
            </Typography>
            <Typography className={classes.userSubTitle}>
              {currentUser.email}
            </Typography>
          </div>
          <ArrowDropDownIcon />
        </Box>
      </Box>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right"
          }}
        >
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <div className="ml-2">Settings</div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </Box>
  );
}
LoggedInSidebarHeader.propTypes = {
  currentUser: userPropType
};

export default function SidebarHeader() {
  const { currentUser, isLoading } = useCurrentUser();

  if (isLoading) return null;

  if (currentUser) {
    return <LoggedInSidebarHeader currentUser={currentUser} />;
  }

  return <AnonymousSidebarHeader />;
}
