import { ComponentProps, FC, useCallback } from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import useI18n from 'components/App/I18nContext/useI18n';

type OnChange = Exclude<ComponentProps<typeof Select>['onChange'], undefined>;
const CurrencySwitcher: FC = () => {
  const { currency, setCurrency, currencyOptions = [] } = useI18n();

  const handleChangeCurrency = useCallback<OnChange>(
    (event) => {
      setCurrency(event.target.value as string);
    },
    [setCurrency]
  );

  return (
    <FormControl>
      <Select value={currency} label="Currency" onChange={handleChangeCurrency}>
        {currencyOptions.map((item) => (
          <MenuItem key={item} value={item} selected={item === currency}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CurrencySwitcher;
