import React from "react";
import PropTypes from "prop-types";

import { List } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

import NavMenuItem from "@jumbo-coremat/CmtNavigation/Vertical/NavMenuItem";
import NavSection from "@jumbo-coremat/CmtNavigation/Vertical/NavSection";
import NavCollapse from "@jumbo-coremat/CmtNavigation/Vertical/NavCollapse";

const propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.func, // a Component function/class
      PropTypes.shape({
        children: PropTypes.array, // of more menuItems
        link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        icon: PropTypes.node,
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        type: PropTypes.oneOf(["section", "collapse", "item"])
      })
    ])
  )
};

const useStyles = makeStyles((_theme) => ({
  sideNavMenu: {
    position: "relative"
  }
}));

export default function CmtVertical({ menuItems }) {
  const classes = useStyles();
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuItems.map((item, index) => {
        if (typeof item === "function") {
          const MenuItem = item;
          return <MenuItem key={index} />;
        }

        switch (item.type) {
          case "section":
            return <NavSection {...item} key={index} />;
          case "collapse":
            return <NavCollapse {...item} key={index} />;
          case "item":
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      })}
    </List>
  );
}
CmtVertical.propTypes = propTypes;
