import defaultContext from "@jumbo-theme/components/contextProvider/AppContextProvider/defaultContext";

import { LAYOUT_TYPES, THEME_TYPES } from "@jumbo-theme/constants/ThemeOptions";

export default {
  ...defaultContext,
  theme: {
    ...defaultContext.theme,
    breakpoints: {
      ...defaultContext.theme.breakpoints,
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1536
      }
    }
  },
  layout: LAYOUT_TYPES.VERTICAL_MINIMAL,
  themeType: THEME_TYPES.DARK
};
