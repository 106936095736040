import EnLang from "@jumbo/i18n/entries/en-US";

export default {
  ...EnLang,

  messages: {
    ...EnLang.messages,
    "appModule.regsiter": "Register", // [sic]
    "resources.analysis.name": "Analysis",
    "resources.portfolio.name": "Portfolio",
    "resources.security.name": "Security",
    "sidebar.dashboard.clients": "Accounts",
    "sidebar.dashboard.portfolios": "Portfolios",
    "sidebar.dashboard.securities": "Securities",
    "sidebar.section.clients": "All Accounts",
    "sidebar.section.portfolios": "All Portfolios",
    "sidebar.section.securities": "Find Security"
  }
};
