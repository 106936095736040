import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import NonIdealIcon from "@material-ui/icons/Warning";
import classnames from "classnames";

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  },

  icon: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto"
  },

  spacing: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
});

class NonIdealState extends PureComponent {
  static propTypes = {
    IconComponent: PropTypes.elementType,
    action: PropTypes.node,
    children: PropTypes.node,
    classes: PropTypes.object, // provided by withStyles
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    elevation: PropTypes.number,
    iconProps: PropTypes.object,
    title: PropTypes.string
  };

  static defaultProps = {
    elevation: 1,
    IconComponent: NonIdealIcon
  };

  renderTitle() {
    const { classes, title } = this.props;

    if (!title) return;

    return (
      <Typography className={classes.spacing} variant="h5" component="h3">
        {title}
      </Typography>
    );
  }

  render() {
    const {
      action,
      children,
      classes,
      description,
      elevation,
      iconProps,
      title,
      IconComponent
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={1} sm={2} md={3} />
        {/* TODO: use BlockWrapper */}
        <Grid item xs={10} sm={8} md={6}>
          <Paper className={classes.root} elevation={elevation}>
            <IconComponent
              className={classnames(classes.icon, classes.spacing)}
              fontSize="large"
              {...iconProps}
            />

            {this.renderTitle()}

            {title && description && <Divider className={classes.spacing} />}

            <div className={classes.spacing}>{description}</div>

            {action}

            {children}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(NonIdealState);
