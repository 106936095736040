import { ComponentProps, FC, Fragment, PropsWithChildren } from 'react';

import NextLink from 'next/link';
import NextImage from 'next/image';
import Button from '@material-ui/core/Button';
import MaterialUiLink from '@material-ui/core/Link';

type LinkProps = ComponentProps<typeof MaterialUiLink> &
  PropsWithChildren<{
    // while we transition from ReactRouter to Next, allow both `to` and `href`
    href: ComponentProps<typeof NextLink>['href'];
    to: ComponentProps<typeof NextLink>['href'];
  }>;
const Link: FC<LinkProps> = ({
  href,
  to,
  color = 'inherit',
  underline = 'none',
  children,
  ...props
}) => {
  return (
    <NextLink href={href || to} passHref>
      <MaterialUiLink color={color} underline={underline} {...props}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <Fragment>{children}</Fragment>
      </MaterialUiLink>
    </NextLink>
  );
};
export default Link;

type ButtonLinkProps = ComponentProps<typeof Button> & {
  href: ComponentProps<typeof NextLink>['href'];
};
export const ButtonLink: FC<ButtonLinkProps> = ({ href, ...props }) => {
  return (
    <NextLink href={href} passHref>
      {/* I'm not sure what's up with this version's Button PropTypes, but
      the code seems to support supplying a component. :shrug: */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Button component="a" {...props} />
    </NextLink>
  );
};

type ImageLinkProps = ComponentProps<typeof NextImage> & {
  href: ComponentProps<typeof NextLink>['href'];
};
export const ImageLink: FC<ImageLinkProps> = ({ href, ...props }) => {
  return (
    <NextLink href={href} passHref>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <a>
        <NextImage {...props} />
      </a>
    </NextLink>
  );
};
