import { createContext, Dispatch } from 'react';

export interface I18nContextProps {
  currency: string;
  setCurrency: Dispatch<string>;
  currencyOptions: string[];
  setCurrencyOptions: Dispatch<string[]>;
  currencySymbol: string;

  asMoney: (
    n: string | number,
    showFractional?: boolean,
    fractionDigits?: number
  ) => string;

  asMoneyDiff: (
    n: string | number,
    showFractional?: boolean,
    fractionDigits?: number
  ) => string;

  asMoneyReduced: (n: string | number, fractionDigits?: number) => string;
}

const I18nContext = createContext({});

export default I18nContext;
