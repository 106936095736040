import { combineReducers } from "redux";

import Common from "@jumbo/redux/reducers/Common";
import Dashboard from "@jumbo/redux/reducers/Dashboard";
import TaskList from "@jumbo/redux/reducers/TaskList";
import MailApp from "@jumbo/redux/reducers/MailApp";
import Chat from "@jumbo/redux/reducers/Chat";
import ContactApp from "@jumbo/redux/reducers/ContactApp";
import ProfileApp from "@jumbo/redux/reducers/ProfileApp";
import WallApp from "@jumbo/redux/reducers/WallApp";
import Auth from "@jumbo/redux/reducers/Auth";
import Users from "@jumbo/redux/reducers/Users";

import DB from "./DB";

export default combineReducers({
  // Jumbo
  auth: Auth,
  chat: Chat,
  common: Common,
  contactApp: ContactApp,
  dashboard: Dashboard,
  mailApp: MailApp,
  profileApp: ProfileApp,
  taskList: TaskList,
  usersReducer: Users,
  wallApp: WallApp,

  // Mustang
  DB
});
