import DataModel, { FormData, Method, Params } from './Model';
import { User as UserSchema } from 'types/schema';

const resourcePath = '/users';
export class User extends DataModel<UserSchema, typeof resourcePath> {
  // yes, the order of user & client is flipped here
  static resourcePath = resourcePath;
  static resourceParam = 'user';
  static cacheKey = 'User';

  signIn(formData: FormData<UserSchema>) {
    return this.collection(Method.Post, 'sign_in', {
      [(this.constructor as typeof User).resourceParam]: formData
    });
  }

  signOut() {
    return this.collection(Method.Destroy, 'sign_out');
  }

  signUp(formData: FormData<UserSchema>) {
    return this.create(formData);
  }
}

const currentUserId = 'me';
export class CurrentUser extends User {
  static CURRENT_USER_ID = currentUserId;

  static getCacheKey(_props: unknown, isCollection: boolean) {
    return User.getCacheKey({ id: this.CURRENT_USER_ID }, isCollection);
  }

  read(_id: string, params?: Params) {
    return User.prototype.read.call(this, currentUserId, params);
  }
}
