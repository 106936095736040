import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { CurrentUserClient } from "resources/UserClient";
import { dispatchToCache } from "src/helpers";

const CurrentUserClientContext = createContext(null);

export function useCurrentUserClient() {
  return useContext(CurrentUserClientContext);
}

// TODO: phase out this HOC in favor of useCurrentUserClient
export function CurrentUserClientProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserClientError, setCurrentUserClientError] = useState(null);
  const [currentUserClient, setCurrentUserClient] = useState({});
  const dispatch = useDispatch();

  const handleFetchUserClient = useCallback(() => {
    setIsLoading(true);

    new CurrentUserClient()
      .read()
      .then((userClient) => {
        setCurrentUserClient(userClient);
        setCurrentUserClientError(null);

        // to support withCurrentUserClient HOC
        dispatchToCache(dispatch, CurrentUserClient, userClient, false);
      })
      .catch((error) => {
        setCurrentUserClient(null);
        setCurrentUserClientError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    handleFetchUserClient();
  }, [handleFetchUserClient]);

  const value = useMemo(
    () => ({
      currentUserClient,
      currentUserClientError,
      isLoading,
      onRefresh: handleFetchUserClient,
      setCurrentUserClient
    }),
    [
      currentUserClient,
      currentUserClientError,
      handleFetchUserClient,
      isLoading
    ]
  );

  return (
    <CurrentUserClientContext.Provider value={value}>
      {children}
    </CurrentUserClientContext.Provider>
  );
}

CurrentUserClientProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CurrentUserClientContext;
