import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";
import { useRouter } from "next/router";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-perfect-scrollbar/dist/css/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-notifications/lib/notifications.css";
import "prismjs/themes/prism-okaidia.css";

import AppContextProvider from "@jumbo-theme/components/contextProvider/AppContextProvider";
import DataModel from "resources/Model";
import CurrentPageComponentContext from "util/CurrentPageComponentContext";
import { CurrentUserProvider } from "components/App/CurrentUserContext";
import { CurrentUserClientProvider } from "components/App/CurrentUserClientContext";
import ErrorBoundary from "components/common/ErrorBoundary";
import I18nProvider from "components/App/I18nContext/Provider";

import { wrapper } from "store";
import JumboAppWrapper from "@jumbo-theme/components/AppWrapper";

export function reportWebVitals(metric) {
  console.log(metric);
}

const queryClient = new QueryClient();

const MainApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const { isPublic = false } = Component;

  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    DataModel.middleware.auth401 = (_Model, _route, response) => {
      const { asPath: destination, pathname: currentPath } = router;

      if (
        response.status === 401 &&
        !isPublic &&
        currentPath !== "/account/login"
      ) {
        router.push({
          pathname: "/account/login",
          query: { destination, source: "api" }
        });
      }

      return response;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>fntech.io | Data-Driven Investment Portfolios</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <I18nProvider>
            <CurrentPageComponentContext.Provider value={Component}>
              <CurrentUserProvider>
                <CurrentUserClientProvider>
                  <JumboAppWrapper>
                    {/* Snackbar must be within JumboAppWrapper or styles get messy */}
                    <SnackbarProvider>
                      {/* <AppWrapper> */}
                      <ErrorBoundary>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                      {/* </AppWrapper> */}
                    </SnackbarProvider>
                  </JumboAppWrapper>
                </CurrentUserClientProvider>
              </CurrentUserProvider>
            </CurrentPageComponentContext.Provider>
          </I18nProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </>
  );
};

MainApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired
};

export default wrapper.withRedux(MainApp);
