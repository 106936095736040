import AdminSecuritiesMenuItem from "components/Navigation/AdminSecuritiesMenuItem";
import ClientsMenuItem from "components/Navigation/ClientsMenuItem";
import PortfoliosMenuItem from "components/Navigation/PortfoliosMenuItem";

// Jumbo theme expects these exported names
export const sidebarNavs = [
  ClientsMenuItem,
  PortfoliosMenuItem,
  AdminSecuritiesMenuItem
];

export const horizontalDefaultNavs = sidebarNavs;

export const minimalHorizontalMenus = sidebarNavs;
