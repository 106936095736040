import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { alpha } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import LanguageSwitcher from "@jumbo-theme/components/AppLayout/partials/LanguageSwitcher";

import Link from "components/common/Link";
import UiSettings from "components/common/UiSettings";
import CurrencySwitcher from "./CurrencySwitcher";

const useStyles = makeStyles((theme) => ({
  buttons: {
    borderLeft: `solid 1px ${alpha(theme.palette.common.dark, 0.15)}`,
    minHeight: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: "relative"
  },

  button: {
    marginLeft: theme.spacing(2)
  },

  link: {
    color: theme.palette.text.primary
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between">
      <Box />
      <Box display="flex" alignItems="center" {...props}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link
              className={classes.link}
              key="privacy-policy"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
            {" | "}
            <Link
              className={classes.link}
              key="terms-of-service"
              to="/terms-of-service"
            >
              Terms of Service
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.buttons}>
        <Box className={classes.button}>
          <CurrencySwitcher />
        </Box>
        <Box className={classes.button}>
          <UiSettings />
        </Box>
        <Box className={classes.button}>
          <LanguageSwitcher />
        </Box>
      </Box>
    </Box>
  );
}
