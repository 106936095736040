import {
  UseBaseQueryOptions,
  useQuery as useQueryBase,
  useQueryClient
} from '@tanstack/react-query';

import DataModel, { Resource, ResponseError, UrlPath } from 'resources/Model';
import { PromisedReturnType } from 'types/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction<R = any> = (...args: any[]) => R;

export type ExtractGenericTypeFromClassConstructor<T> = T extends {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): DataModel<infer U, infer V>; // NOSONAR
}
  ? [U, V]
  : undefined; // never caused TS errors for classes with RouteParams :shrug:

// export function createMemberQueryHooks<
//   T extends typeof DataModel<R, P>,
//   R extends Resource = ExtractGenericTypeFromClassConstructor<T>[0],
//   P extends string = ExtractGenericTypeFromClassConstructor<T>[1]
// >(ResourceClass: T, memberMethod: keyof DataModel<R, P>) {
//   return (...args: Parameters<typeof ResourceClass.prototype[typeof memberMethod]>) => {
//     return useQuery<PromisedReturnType<typeof ResourceClass.prototype[typeof memberMethod]>, ResponseError>({
//       queryKey: [ResourceClass.resourcePath, ...args],
//       queryFn: () => ResourceClass.prototype[memberMethod].apply(new ResourceClass({}), args)
//     });
//   };
// }

function createQueryHooksGenerator<
  T extends typeof DataModel<R, P>,
  R extends Resource = ExtractGenericTypeFromClassConstructor<T>[0],
  P extends UrlPath = ExtractGenericTypeFromClassConstructor<T>[1]
>(ResourceClass: T) {
  return <M extends AnyFunction>(method: M) => {
    const useQuery = (
      useQueryArguments: UseBaseQueryOptions<
        PromisedReturnType<typeof method>,
        ResponseError
      >,
      instanceParameters?: ConstructorParameters<T>[0]
    ) => {
      const params = instanceParameters ?? null;
      const useResourceQuery = (...args: Parameters<typeof method>) => {
        return useQueryBase<PromisedReturnType<typeof method>, ResponseError>({
          ...useQueryArguments,
          queryKey: [ResourceClass.resourcePath, ...args],
          queryFn: () => method.apply(new ResourceClass(params), args)
        });
      };

      return useResourceQuery;
    };

    const useClearQueryCache = (...args: Parameters<typeof method>) => {
      const queryClient = useQueryClient();

      queryClient.invalidateQueries({
        queryKey: [ResourceClass.resourcePath, ...args]
      });
    };

    const useReplaceQueryCache = (...args: Parameters<typeof method>) => {
      const queryClient = useQueryClient();

      return (data: PromisedReturnType<typeof method>) => {
        queryClient.setQueryData([ResourceClass.resourcePath, ...args], data);
      };
    };

    return {
      useQuery,
      useClearQueryCache,
      useReplaceQueryCache,
      Model: ResourceClass
    };
  };
}

export function createUseResourcesHook<
  T extends typeof DataModel<R, P>,
  R extends Resource = ExtractGenericTypeFromClassConstructor<T>[0],
  P extends UrlPath = ExtractGenericTypeFromClassConstructor<T>[1]
>(ResourceClass: T) {
  const hooksGeneratorForMethod = createQueryHooksGenerator(ResourceClass);

  const prototype = ResourceClass.prototype as DataModel<R, P>;

  return hooksGeneratorForMethod(prototype.list);
}

export function createUseResourceHook<
  T extends typeof DataModel<R, P>,
  R extends Resource = ExtractGenericTypeFromClassConstructor<T>[0],
  P extends UrlPath = ExtractGenericTypeFromClassConstructor<T>[1]
>(ResourceClass: T) {
  const hooksGeneratorForMethod = createQueryHooksGenerator(ResourceClass);

  const prototype = ResourceClass.prototype as DataModel<R, P>;

  return hooksGeneratorForMethod(prototype.read);
}
