import DataModel from './Model';
import { Portfolio as PortfolioSchema } from 'types/schema';

const resourcePath = '/portfolios';
export class Portfolio extends DataModel<PortfolioSchema, typeof resourcePath> {
  static resourcePath = resourcePath;
  static resourceParam = 'portfolio';
  static cacheKey = 'Portfolio';

  static MemberRoutes = {
    Archive: 'archive',
    Clone: 'clone',
    ImportFromIndex: 'import_from_index'
  };

  static CollectionRoutes = {
    References: 'references'
  };
}

// this is an unfortunate hack to support collection methods, which might
// otherwise overwrite Securities stored in cache
export class ReferencePortfolio extends Portfolio {
  static getCacheKeyBaseName(props = {}) {
    const parentMethod = Portfolio.getCacheKeyBaseName;
    return `${parentMethod.call(this, props)}::ReferencePortfolios`;
  }
}
