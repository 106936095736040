import DataModel, { FormData, Id, Params } from './Model';
import { ClientUser as UserClientSchema } from 'types/schema';

const resourcePath = '/client_users';
export class UserClient extends DataModel<
  UserClientSchema,
  typeof resourcePath
> {
  // yes, the order of user & client is flipped here
  static resourcePath = resourcePath;
  static resourceParam = 'client_user';
  static cacheKey = 'UserClient';
}

const currentClientId = 'current';
export class CurrentUserClient extends UserClient {
  static CURRENT_CLIENT_ID = currentClientId;

  static getCacheKey(_props: unknown, isCollection: boolean) {
    return UserClient.getCacheKey({ id: this.CURRENT_CLIENT_ID }, isCollection);
  }

  read(_id?: Id, params?: Params) {
    return UserClient.prototype.read.call(this, currentClientId, params);
  }

  update(_id: Id, formData: Partial<FormData<UserClientSchema>>) {
    return UserClient.prototype.update.call(this, currentClientId, formData);
  }
}
