import {
  addCollectionToCacheAction,
  addMemberToCacheAction
} from "reducers/DB";

const AUTH_ROUTES = ["/account/login", "/account/register"];

export function getServerRedirect(context, error) {
  const { resolvedUrl: destination = "/" } = context;

  const currentPath = destination.replace(/\?.*/, "");

  if (error?.response?.status === 401 && !AUTH_ROUTES.includes(currentPath)) {
    let search = new URLSearchParams({
      destination,
      source: "server"
    }).toString();
    if (search) search = `?${search}`;

    return {
      destination: `/account/login${search}`,
      permanent: false
    };
  }
}

export function getDefaultRequestOptions(nextRequestContext) {
  const {
    req: { cookies = {} }
  } = nextRequestContext;

  return {
    host: process.env.API_SERVER_HOST,
    headers: {
      Cookie: Object.keys(cookies)
        .map((k) => `${k}=${encodeURIComponent(cookies[k])}`)
        .join("; ")
    },
    middleware: {
      setCurrentUserClientCookie:
        getApplyLegacySessionCookieMiddleware(nextRequestContext)
    }
  };
}

function ignoreError(error) {
  // eslint-disable-next-line no-console
  console.warn("Promise error:", error);
  return null;
}

export function failSilently(...promises) {
  // ignore promises, try again client-side
  return promises.map((p) => p.catch(ignoreError));
}

export function dispatchToCache(dispatch, ModelClass, modelData, isCollection) {
  // I would normally suggest not doing this check, but this method is designed
  // to be used by Pages to sync server and client, not to clear caches in the
  // client. doing the check here allows us to DRY pages
  if (!modelData) return;

  // we pass modelData here most for !collection cache keys to get an id. It is
  // (as of writing) always ignored fro collection cache keys, but if that were
  // to change, this method would need to be smarter
  const getCacheKey = () => ModelClass.getCacheKey(modelData, isCollection);

  const writeMethod = isCollection
    ? addCollectionToCacheAction
    : addMemberToCacheAction;

  console.log("dispatchToCache", { writeMethod, isCollection, modelData });

  writeMethod(dispatch, getCacheKey)(modelData);
}

export function getApplyLegacySessionCookieMiddleware(context) {
  const { res } = context || {};

  return (_Model, _route, response) => {
    [...response.headers]
      .filter(([n]) => n.toLowerCase() === "set-cookie")
      .forEach(([n, v]) => res.setHeader(n, v));

    return response;
  };
}
