import React from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import headerDarkTheme from "assets/images/portfolio-catapult-header-dark-theme.svg";
import headerLightTheme from "assets/images/portfolio-catapult-header-light-theme.svg";
import headerIconDarkTheme from "assets/images/portfolio-catapult-header-icon-dark-theme.svg";
import headerIconLightTheme from "assets/images/portfolio-catapult-header-icon-light-theme.svg";

import { ImageLink } from "components/common/Link";

const propTypes = {
  color: PropTypes.oneOf(["white", "black"])
};

export default function Logo({ color, ...props }) {
  const logo = color === "white" ? headerDarkTheme : headerLightTheme;
  const logoSymbol =
    color === "white" ? headerIconDarkTheme : headerIconLightTheme;

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <ImageLink
          href="/"
          alt="logo"
          priority
          {...logo}
          height="40"
          width="132"
        />
      </Hidden>
      <Hidden smUp initialWidth="xs">
        <ImageLink
          href="/"
          alt="logo"
          priority
          {...logoSymbol}
          height="40"
          width="40"
        />
      </Hidden>
    </Box>
  );
}

Logo.propTypes = propTypes;
