import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import IntlMessages from "@jumbo-theme/utils/IntlMessages";

import { useCurrentUser } from "components/App/CurrentUserContext";
import { isAdmin } from "components/common/AdminOnly";

const propTypes = {
  error: PropTypes.instanceOf(Error),
  i18nKeys: PropTypes.objectOf(PropTypes.string)
};

export default function Error404({ error, i18nKeys }) {
  const { currentUser } = useCurrentUser();
  const { resourceName } = i18nKeys || {};

  if (isAdmin(currentUser)) {
    console.log("404", { error });
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100%" }}
    >
      {resourceName && <IntlMessages id={resourceName} />}
      <IntlMessages id="extraPages.notFound" />
    </Box>
  );
}
Error404.propTypes = propTypes;
