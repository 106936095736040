import React from "react"; // should not be here
import ReactGA from "react-ga";

import { fetchStart, fetchSuccess, fetchError } from "@jumbo/redux/actions";
import {
  setAuthUser,
  // setForgetPassMailSent,
  updateLoadUser
} from "@jumbo/redux/actions/Auth";

import { User } from "resources/User";

// const userModel = new User(null, { host: "" });
const userModel = new User();

// temporary until middleware sets a cookie
export function setCurrentUser(user) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("id_token", (user || {}).id || "");
  }
}

export const START_GET_CURRENT_USER = "Auth/START_GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "Auth/GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "Auth/GET_CURRENT_USER_FAILURE";
export const START_LOGIN = "Auth/START_LOGIN";
export const LOGIN_SUCCESS = "Auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "Auth/LOGIN_FAILURE";
export const START_REGISTRATION = "Auth/REGISTRATION_LOGIN";
export const REGISTRATION_SUCCESS = "Auth/REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "Auth/REGISTRATION_FAILURE";
export const RESET_ERROR = "Auth/RESET_ERROR";
export const LOGIN_USER = "Auth/LOGIN_USER";
export const SIGN_OUT_SUCCESS = "Auth/SIGN_OUT_SUCCESS";

export const startGetCurrentUser = (...args) => ({
  type: START_GET_CURRENT_USER,
  ...args
});

export const getCurrentUserSuccess = (...args) => ({
  type: GET_CURRENT_USER_SUCCESS,
  ...args
});

export const getCurrentUserFailure = (...args) => ({
  type: GET_CURRENT_USER_FAILURE,
  ...args
});

export const startLogin = (...args) => ({
  type: START_LOGIN,
  ...args
});

export const loginSuccess = (...args) => ({
  type: LOGIN_SUCCESS,
  ...args
});

export const loginFailure = (...args) => ({
  type: LOGIN_FAILURE,
  ...args
});

export const startRegistration = (...args) => ({
  type: START_REGISTRATION,
  ...args
});

export const registrationSuccess = (...args) => ({
  type: REGISTRATION_SUCCESS,
  ...args
});

export const registrationFailure = (...args) => ({
  type: REGISTRATION_FAILURE,
  ...args
});

export const resetError = (...args) => ({
  type: RESET_ERROR,
  ...args
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS
});

const BasicAuth = {
  onRegister(params, options = {}) {
    const { email, password } = params;
    const { onSuccess } = options;

    return (dispatch) => {
      dispatch(fetchStart()); // @jumbo action
      dispatch(startRegistration());

      if (!!email && !!password) {
        userModel
          .signUp({ email, password, password_confirmation: password })
          .then((user) => {
            dispatch(fetchSuccess()); // @jumbo action
            setCurrentUser(user); // remove w/middleware cookies
            dispatch(registrationSuccess(user));

            ReactGA.set({ userId: user.id });
            ReactGA.event({
              category: "Actions",
              action: "Sign Up"
            });

            if (onSuccess) onSuccess(user);

            return user;
          })
          .catch(({ message }) => {
            dispatch(fetchError(message)); // @jumbo action
            dispatch(registrationFailure({ message }));
          });
      } else {
        dispatch(fetchError()); // @jumbo action
        dispatch(registrationFailure());
      }
    };
  },

  onLogin(params, options = {}) {
    const { email, password, rememberMe } = params;
    const { onSuccess } = options;

    return (dispatch) => {
      dispatch(fetchStart()); // @jumbo action
      dispatch(startLogin());

      if (!!email && !!password) {
        userModel
          .signIn({ email, password, remember_me: rememberMe })
          .then((user) => {
            dispatch(fetchSuccess()); // @jumbo action
            setCurrentUser(user); // remove w/middleware cookies
            dispatch(loginSuccess(user));

            ReactGA.set({ userId: user.id });
            ReactGA.event({
              category: "Actions",
              action: "Sign In"
            });

            if (onSuccess) onSuccess(user);

            return user;
          })
          .catch(({ message }) => {
            dispatch(fetchError(message)); // @jumbo action
            dispatch(loginFailure({ message }));
          });
      } else {
        dispatch(fetchError()); // @jumbo action
        dispatch(loginFailure());
      }
    };
  },

  onLogout(options = {}) {
    const { onSuccess } = options;
    return (dispatch) => {
      dispatch(fetchStart()); // @jumbo action
      userModel
        .signOut()
        .then(() => {
          dispatch(fetchSuccess()); // @jumbo action
          setCurrentUser(null); // remove w/middleware cookies
          dispatch(signOutSuccess());

          ReactGA.set({ userId: null });
          ReactGA.event({
            category: "Actions",
            action: "Sign Out"
          });

          if (onSuccess) onSuccess();
        })
        .catch(({ message }) => {
          dispatch(fetchError(message)); // @jumbo action
          dispatch(loginFailure({ message }));
        });
    };
  },

  getAuthUser(loaded = false, options = {}) {
    const { onSuccess } = options;

    return (dispatch) => {
      dispatch(fetchStart()); // @jumbo action
      dispatch(updateLoadUser(loaded)); // @jumbo action
      dispatch(startGetCurrentUser());

      userModel
        .me()
        .then((user) => {
          dispatch(fetchSuccess()); // @jumbo action
          dispatch(setAuthUser(user)); // @jumbo action
          setCurrentUser(user);
          dispatch(getCurrentUserSuccess(user));

          ReactGA.set({ userId: user.id });

          if (onSuccess) onSuccess(user);

          return user;
        })
        .catch(({ message }) => {
          // "signIn.error"
          dispatch(fetchError(message)); // @jumbo action
          dispatch(getCurrentUserFailure({ message }));
        });
    };
  },

  onForgotPassword() {
    return (_dispatch) => {
      // dispatch(fetchStart());
      // setTimeout(() => {
      //   dispatch(setForgetPassMailSent(true));
      //   dispatch(fetchSuccess());
      // }, 300);
    };
  },

  getSocialMediaIcons() {
    return <React.Fragment> </React.Fragment>;
  }
};

export default BasicAuth;
