import { CurrentUserClient } from 'resources/UserClient';

import { createUseResourceHook } from './createQueryHooks';

const {
  useQuery: useCurrentUserClient,
  useClearQueryCache,
  useReplaceQueryCache
} = createUseResourceHook(CurrentUserClient);

export const useClearContentCache = useClearQueryCache;
export const useReplaceContentCache = useReplaceQueryCache;
export default useCurrentUserClient;
