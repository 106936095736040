import React from "react";
import PropTypes from "prop-types";
import { useCurrentUser } from "components/App/CurrentUserContext";

const propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ])
};

export function isAdmin(user) {
  return user?.is_admin;
}

function AdminOnlyComponent({ children, innerRef, ...restProps }) {
  const { currentUser } = useCurrentUser();

  if (!isAdmin(currentUser)) return null;

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { ...restProps, ref: innerRef })
  );
}
AdminOnlyComponent.propTypes = propTypes;

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => (
  <AdminOnlyComponent {...props} innerRef={ref} />
));
