import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { CurrentUser } from "resources/User";
import { dispatchToCache } from "src/helpers";

const CurrentUserContext = createContext(null);

export function useCurrentUser() {
  return useContext(CurrentUserContext);
}
export function CurrentUserProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUserError, setCurrentUserError] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const dispatch = useDispatch();

  const handleFetchUser = () => {
    setIsLoading(true);

    new CurrentUser()
      .read()
      .then((user) => {
        setCurrentUser(user);
        setCurrentUserError(null);

        // to support withCurrentUser HOC
        dispatchToCache(dispatch, CurrentUser, user, false);
      })
      .catch((error) => {
        setCurrentUser(null);
        setCurrentUserError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleFetchUser, []);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        currentUserError,
        isLoading,
        onRefresh: handleFetchUser,
        setCurrentUser
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CurrentUserContext;
